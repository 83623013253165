import common from '@/common'

const staffFormMixin = {
  methods: {
    handleSubmitError(err, callBellFacilitys) {
      const { data, result_msg, return_code } = err.cause?.respond
      let html

      // handel Login NFC
      if (
        result_msg === "StaffLoginNfcExist" && data?.nfcUsageList?.length > 0) {
        html = data.nfcUsageList.map(item => {
          return `<span class="wh-font-family-monospace">${item.serialNumber.toUpperCase()}</span>`
        }).join(';<br />')
      }

      // handle CallBell NFC
      if (result_msg === "CallBellNfcIsUsing" && data?.nfcUsageList?.length > 0) {
        html = data.nfcUsageList.map(item => {
          return (common.isAdmin() || callBellFacilitys.includes(item.facilityID))
            ? `<span class="wh-font-family-monospace">${item.serialNumber.toUpperCase()}</span> <span>(${item.facilityName}, ${item.staffName})</span>`
            : `<span class="wh-font-family-monospace">${item.serialNumber.toUpperCase()}</span>`
        }).join(';<br />')
      }

      // Show Alert
      common.showAlert({
        title: common.getI18n(`error.${result_msg}`),
        html,
      })
    }
  }
}
export default staffFormMixin
