<template>
  <div>
    <template v-for="item in facilityAndRoleList">
      <b-form-checkbox
        class="mt-1"
        :disabled="!isAdmin && item.facilityID !== role.facilityID"
        :value="item.facilityID"
        v-model="selectedFacilityID"
      >
        {{item.facilityName}}
      </b-form-checkbox>

      <v-select
        v-if="item.roles.length > 0 && selectedFacilityID.includes(item.facilityID)"
        class="mt-1"
        label="roleName"
        v-model="selectedRole[item.facilityID]"
        :options="item.roles"
        :reduce="roleName => roleName.roleID"
        @option:selected="selectedOption"
        :clearable="false"
        :searchable="false"
      >
        <template #no-options>
          {{$t('common.no_options')}}
        </template>
      </v-select>

      <b-form-input
        v-if="item.roles.length === 0 && selectedFacilityID.includes(item.facilityID)"
        class="mt-1"
        readonly
        :value="$t('staff.no_role')"
      />

    </template>
  </div>

</template>

<script>
import {
  BRow, BCol, BCard, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, BFormRadio,BInputGroupAppend, BCardBody
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import common from "@/common";

export default {
  name: "slotFacilityRoleIDs",
  components: {
    BRow, BCol, BCard, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, BFormRadio,BInputGroupAppend, BCardBody,
    vSelect
  },
  props: {
    companyId: {type: Number},
    rowData: {type: Object}
  },
  data() {
    return {
      facilityAndRoleList: [],
      selectedRole: {},
      selectedFacilityID: [],
      isAdmin: common.isAdmin(),
      role: common.getRole(),
    }
  },
  mounted() {
    this.getFacilityAndRoleList()

    const obj = {}
    for (const i in this.rowData['facilityRoleIDs']) {
      this.selectedFacilityID.push(this.rowData['facilityRoleIDs'][i].facilityID)
      obj[this.rowData['facilityRoleIDs'][i].facilityID] = this.rowData['facilityRoleIDs'][i].roleID
    }

    this.selectedRole = obj

  },
  watch: {
    companyId: function () {
      this.getFacilityAndRoleList()
    },
    selectedFacilityID: function (current, old) {
      if (old.length > current.length) {
        const diff = old.filter(function (v) {
          return current.indexOf(v) === -1
        })

        delete this.selectedRole[diff[0]]
        this.buildData('selectedFacilityID', diff[0])
      }

      if (current.length === 0) {
        this.rowData['facilityRoleIDs'] = []
      }
    }
  },
  methods: {
    getFacilityAndRoleList: function () {
      if (event && event.type !== 'readystatechange' && event.type !== 'transitionend') {
        this.rowData['facilityRoleIDs'] = this.selectedFacilityID = []
        this.selectedRole = {}
      }

      common.apiGetData('/facility/GetFacilityAndRoleList?companyID='+this.companyId).then(res => {
        this.facilityAndRoleList = res.data.list

        for (const i in this.facilityAndRoleList) {
          for (const k in this.facilityAndRoleList[i].roles) {
            this.facilityAndRoleList[i].roles[k]['facilityID'] = this.facilityAndRoleList[i].facilityID
          }
        }
        this.$emit('onRoleListChange', this.facilityAndRoleList, res.data)
      })
    },
    selectedOption: function (val) {
      this.buildData('selectedOption', val.facilityID, val.roleID)
    },
    buildData: function (type, facility_id, role_id = 0) {
      if (type === 'selectedFacilityID') {
        // this.selectedRole = {}
        delete this.selectedRole[facility_id]
      } else if (type === 'selectedOption') {
        this.selectedRole[facility_id] = role_id
      }

      let arr = []
      for (const i in this.selectedRole) {
        arr.push({facilityID: parseInt(i), roleID: this.selectedRole[i]})
      }

      this.rowData['facilityRoleIDs'] = arr
    },
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
