const nfcUtils = {
  // 6 到 16 bit 的十六進制字符串，bit 之間可以全帶或全不帶半角冒號
  regexNfc: /^(?:[A-Fa-f0-9]{2}:){2,7}[A-Fa-f0-9]{2}$|^(?:[A-Fa-f0-9]{2}){3,8}$/,

  getConvertedNfc(nfc) {
    return nfc.trim().replace(/[^A-Fa-f0-9]/g, '').toUpperCase()
  },
}

export default nfcUtils
