<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"/>

    <edit-field
      :is-edit="false"
      :fields="fields"
      :row-data="row_data"
      post-api="/staffmanage/AddStaff"
      finish-router="staff"
      permission="Sta_Create"
      :submit-action="submitAction"
    >
      <template #facility="scope">
        <SlotFacilityRoleIDs
          :company-id="scope.rowData.companyID"
          :row-data="scope.rowData"
          @onRoleListChange="onRoleListChange"/>
      </template>

      <template v-slot:loginNfcs="scope">
        <field-login-nfcs
          :is-edit="scope.isEdit"
          :root-item="scope.rootItem"
          :items="row_data['loginNfcs']"
          :show-add-btn="true"
          :show-delete-btn="true"
          @updateValues="(value) => row_data['loginNfcs'] = value"/>
      </template>

      <template v-slot:callBellNfcs="scope">
        <field-modal-call-bell-nfcs
          :is-edit="scope.isEdit"
          :row-data="scope.rowData"
          :root-item="scope.rootItem"
          :items="row_data['callBellNfcs']"
          :facilityAndRoleList="facilityAndRoleList"
          @updateValues="(value) => row_data['callBellNfcs'] = value"
          @onUpdateIsLoginNfc="onUpdateIsLoginNfc"/>
      </template>

    </edit-field>
  </page-content>
</template>

<script>
import fields, { reloadFieldData } from "@/views/staff/fields";
import EditField from "@/components/EditField2";
import EditFieldHeader from "@/components/EditFieldHeader";
import PageContent from "@/components/PageContent";
import SlotFacilityRoleIDs from "@/views/staff/SlotFacilityRoleIDs";
import common from "@/common";
import FieldLoginNfcs from './FieldLoginNfcs.vue'
import FieldModalCallBellNfcs from './FieldModalCallBellNfcs.vue'
import staffFormMixin from "@/views/staff/staffFormMixin"
import _ from 'lodash'

export default {
  name: "Add",
  components: {
    SlotFacilityRoleIDs,
    PageContent,
    EditFieldHeader,
    EditField,
    fields,
    FieldLoginNfcs,
    FieldModalCallBellNfcs,
  },
  mixins: [staffFormMixin],
  data() {
    return {
      title: common.getMenuName('staff') + ' - ' + common.getI18n('common.add'),
      fields,
      facilityAndRoleList: [],

      // 如果是checkbox，里面写 field: [], 如ids: []
      row_data: {
        isActive: true,
        companyID: common.getCompanyId(),
        loginNfcs: [],
        callBellNfcs: [],
      }
    }
  },
  created() {
    reloadFieldData()
  },
  methods: {
    onRoleListChange(facilityAndRoleList, { callBellFacilitys }) {
      this.facilityAndRoleList = facilityAndRoleList

      // 動態顯示緊急鍵 NFC
      const isShowCallBellNfc = !!(callBellFacilitys && callBellFacilitys.length > 0)
      this.fields.filter(f => ['callBellNfcTitle', 'callBellNfcs'].includes(f.name))
        .forEach(f => f.add_show = isShowCallBellNfc)

      // 啓用了緊急鍵的 facility
      this.row_data.callBellFacilitys = [...callBellFacilitys] ?? []
    },
    onUpdateIsLoginNfc(value, nfc) {
      const loginNfcs = this.row_data['loginNfcs']
      if (value && !loginNfcs.includes(nfc)) {
        this.row_data['loginNfcs'].push(nfc)
      }
      if (!value && loginNfcs.includes(nfc)) {
        this.row_data['loginNfcs'] = loginNfcs.filter(n => n !== nfc)
      }
    },
    submitAction(rowData, api, finishRouter) {
      const that = this
      return submit()

      function submit() {
        that.$requestWehealth({ method: 'post', url: api, data: rowData })
          .then(() => that.$router.push({ name: finishRouter }))
          .catch((err) => that.handleSubmitError(err, that.row_data.callBellFacilitys))
      }
    },
  },
}
</script>

<style scoped>

</style>
